import React, {SyntheticEvent, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {IconButton, TextField, Tooltip} from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {useHttp} from "../../hooks/useHttp";
import {requests} from "../../helpers/requests";
import {useSession} from "../../hooks/useSession";
import {useDoctor} from "../../hooks/useDoctor";

export default function EditDescription() {
    const [open, setOpen] = React.useState(false);
    const {loading, request, error, clearError} = useHttp()
    const session = useSession()
    const {token} = useDoctor().doctor
    const [description, setDescription] = useState('')

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleChangeDescription = (event: SyntheticEvent) => {
        // @ts-ignore
        setDescription(event.target.value)
        clearError()
    }

    const handleClickEditDescription = async () => {
        let tempBody = {
            description,
            sessionId: session.session.id
        }
        try {
            await request(requests.addDescription.url, requests.addDescription.method, tempBody,token)
            session.editDescription(description)
            handleClose()
        }catch (e) {
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title={"редактировать"}>
                <IconButton  edge="end" onClick={handleClickOpen}>
                    <EditOutlinedIcon/>
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Введите новое описание"}</DialogTitle>
                <DialogContent>
                    <form onSubmit={event => {
                        event.preventDefault()
                        handleClickEditDescription().then()
                    }}>
                        <TextField
                            id="standard-basic"
                            label={"Описание"}
                            onChange={handleChangeDescription}
                            error={!!error}
                            helperText={error && "Что-то пошло не так. Поробуйте уменьшить размер описания."}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        disabled={loading}
                    >
                        Отмена
                    </Button>
                    <Button
                        onClick={handleClickEditDescription}
                        color="primary"
                        autoFocus
                        disabled={loading}
                    >
                        Изменить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
