import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import PatientMob from "../../store/PatientMob";
import { ListItemSecondaryAction} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {routes} from "../../helpers/paths";
import DeletePatient from "./DeletePatient";

interface PatientListItemProps {
    patient: PatientMob,
    removePatient: Function,
}

export const PatientListItem = ({patient, removePatient }:PatientListItemProps) => {
    const history = useHistory()

    return(
        <ListItem
            button
            onClick={() => history.push(routes.goToPatientPage(patient.patient.id))}
        >
            <ListItemAvatar>
                <Avatar>
                    {patient.patient.name[0]}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={patient.patient.name}
            />
            <ListItemSecondaryAction>
               <DeletePatient removePatient={removePatient} patientId={patient.patient.id}/>
            </ListItemSecondaryAction>
        </ListItem>
    )
}