import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {IconButton, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {useHttp} from "../../hooks/useHttp";
import {requests} from "../../helpers/requests";
import {useDoctor} from "../../hooks/useDoctor";


export default function DeletePatient({removePatient, patientId}:{removePatient: Function, patientId: number}) {
    const [open, setOpen] = React.useState(false);
    const {request, loading} = useHttp()
    const doctor = useDoctor()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleDelete = async () => {
        try{
            await request(requests.unlinkPatient.url(patientId), requests.unlinkPatient.method, null, doctor.doctor.token)
            removePatient()
            handleClose()
        }catch (e) {
            
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title={"Удалить пациента"}>
                <IconButton
                    color={"secondary"}
                    aria-label="delete"
                    onClick={handleClickOpen}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Вы уверены, что хотите удалить пациента?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button
                        onClick={handleDelete}
                        color="primary"
                        autoFocus
                        disabled={loading}
                    >
                        Удалить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
