import React from "react"
import Header from "../components/Header/Header";
import {Container} from "@material-ui/core";
import {DynamicCard} from "../containers/PatientPage/DynamicCard";


export const UserDynamicPage = () => {
    return(
        <>
            <Header/>
            <Container>
                <DynamicCard/>
            </Container>
        </>
    )
}