export const requests = {
    login:{
        url:'api/users/user/login/',
        method: 'GET'
    },
    getPatient:{
        url:(patientId: number) => `api/patient/${patientId}`,
        method: 'GET'
    },
    unlinkPatient:{
        url:(patientId: number) => `api/users/patients/patient/unlink/${patientId}`,
        method: 'DELETE'
    },
    getMyPatients:{
        url: 'api/users/patients/my',
        method: 'GET'
    },
    getSessionResultsById:{
        url:(sessionId: number) => `api/sessions/session/results/${sessionId}`,
        method: 'GET'
    },
    linkSessionToPatient:{
        url:(sessionKey: number, patientId: number) => `api/sessions/link/${sessionKey}/${patientId}`,
        method: 'PUT'
    },
    addDescription:{
        url: 'api/sessions/session/description',
        method: 'PUT'
    },
    getSessionsByPatientId:{
        url:(patientId: number) => `api/sessions/my/${patientId}`,
        method: 'GET'
    },
    getAllDoctorsSessions:{
        url:'api/sessions/my',
        method:'GET'
    },
    getAllQuestsId:{
        url:'api/simulators',
        method:'GET'
    },
    getStatisticForPatient:{
        url:(patientId: number, questId:number) => `api/quests/statistic/by/${questId}/${patientId}`,
        method: 'GET'
    },
    createPatient:{
        url:'api/users/user/new/patient',
        method: 'POST'
    },
    linkPatientToDoctor:{
        url: (patientId:number) => `api/users/patients/patient/link/${patientId}`,
        method: 'PUT'
    },
    shutDownSession:{
        url: (sessionId:number) => `api/sessions/session/close/${sessionId}`,
        method:'PUT'
    },
    fullSessionGet:{
        url: (sessionId:number) => `api/sessions/session/${sessionId}/results`,
        method:'GET'
    },
    autoLinkDoctor:{
        url: `api/users/user/new/patient/autoLink`,
        method:'POST'
    }
}