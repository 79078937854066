import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {useSession} from "../../hooks/useSession";
import {Grid, ListItemIcon, ListItemSecondaryAction, Paper, Typography} from "@material-ui/core";
import {dateBeautifyFromUTC, timeBeautifyFromUTCtoTimeString} from "../../helpers/beutifyDateHelpers";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DescriptionIcon from '@material-ui/icons/Description';
import EditDescription from "../../components/SessionPage/EditDescription";
import {observer, useObserver} from "mobx-react-lite";
import ShutDownSession from "../../components/SessionPage/ShutDownSession";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        list: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        paper:{
            margin: theme.spacing(2,0),
            padding: theme.spacing(2)
        },
        grid:{
            marginBottom: theme.spacing(1)
        }
    }),
);

export const SessionMainCard = observer(() => {
    const classes = useStyles();
    const session = useSession().session
    const quests = useSession().questList

    const sessionArr = [
        {
            primary:"Дата сессии",
            secondary: dateBeautifyFromUTC(session.creationDateTime),
            icon: <CalendarTodayIcon/>
        },
        {
            primary:"Время начала",
            secondary: timeBeautifyFromUTCtoTimeString(session.creationDateTime),
            icon: <AccessTimeIcon/>
        },
        {
            primary:"Описание",
            secondary: session.description? session.description : 'Описания нет',
            icon: <DescriptionIcon/>
        },
    ]

    return useObserver(() => (
        <>
            <Paper elevation={3} className={classes.paper}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                    className={classes.grid}
                >
                    <Grid item>
                        <Typography component={"h2"} variant={"h5"}>
                            Основная информация
                        </Typography>
                    </Grid>
                    {!session.isFinished && quests.length !== 0 &&
                        <Grid item>
                            <ShutDownSession/>
                        </Grid>}
                </Grid>
                <List className={classes.list}>
                    {sessionArr.map((elem, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                {elem.icon}
                            </ListItemIcon>
                            <ListItemText primary={elem.primary} secondary={elem.secondary}/>
                            {elem.primary === "Описание" &&
                            <ListItemSecondaryAction>
                                <EditDescription/>
                            </ListItemSecondaryAction>}
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </>
    ));
})
