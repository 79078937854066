import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Fab, Tooltip} from "@material-ui/core";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import {createStyles, makeStyles} from "@material-ui/core/styles";
import CustomSnackbar from "../CustomSnackbar";
import {useSession} from "../../hooks/useSession";
import {useDoctor} from "../../hooks/useDoctor";
import {useHttp} from "../../hooks/useHttp";
import {requests} from "../../helpers/requests";

const useStyles = makeStyles(() =>
    createStyles({
        fab: {
            color: '#fff'
        }
    }),
);

export default function ShutDownSession() {
    const classes = useStyles()
    const {id} = useSession().session
    const {shutDownSession} = useSession()
    const {token} = useDoctor().doctor
    const {request, clearError, error, loading} = useHttp()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleShutDownSession =  async () =>{
        try {
            await request(requests.shutDownSession.url(id), requests.shutDownSession.method, null, token)
            shutDownSession()
            handleClose()
        }catch (e) {
            handleClose()
        }
    }

    return (
        <>
            <Tooltip title={"Завершить сессию"}>
                <Fab
                    color="secondary"
                    aria-label="shut-down-session"
                    className={classes.fab}
                    size={"small"}
                    onClick={handleClickOpen}
                >
                    <PowerSettingsNewIcon/>
                </Fab>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">{"Вы уверены, что хотите завершить сессию?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button
                        onClick={handleShutDownSession}
                        color="primary"
                        autoFocus
                        disabled={loading}
                    >
                        Завершение
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomSnackbar
                open={!!error}
                handleClose={()=>{clearError()}}
                message={"Не возможно завершить сессию. Попробуйте позже."}
                kind={"error"}
            />
        </>
    );
}
