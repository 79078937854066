import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import {dateBeautifyFromDateToServerString} from "../../helpers/beutifyDateHelpers";
import {usePatientAdd} from "../../hooks/usePatientAdd";

export default function BirthdayPicker() {
    const patient = usePatientAdd()
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date()
    );

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        if(date)
            patient.setBirthday(dateBeautifyFromDateToServerString(date))
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Дата рождения"
                format="dd.MM.yyyy"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    );
}
