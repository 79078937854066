import {action, computed, observable} from "mobx";
import {DynamicDataInterface, DynamicQuestsInterface, DynamicResponseInterface} from "../interfaces/DynamicInterface";
import {dateBeautifyFromTimeString, dateBeautifyFromUTC} from "../helpers/beutifyDateHelpers";
import { httpHelper } from "../helpers/httpHelper";
import {requests} from "../helpers/requests";


class DynamicMob {
    @observable.shallow allDynamic : DynamicResponseInterface[] = []
    @observable.shallow currentData : DynamicDataInterface[] = []
    @observable.shallow currentQuests : DynamicQuestsInterface[] =[]
    @observable currentTrainer : number | '' = -1
    @observable currentQuest : DynamicQuestsInterface | '' = {
        id: -1,
        description: '',
        descriptionEn: '',
        dominantHand:{
            description:'',
            id: -1,
            name: ''
        },
        name: '',
        nameEn: '',
        timeToComplete: ''
    }


    constructor(dynamic: DynamicResponseInterface[] | null) {
        if(dynamic){
            this.allDynamic = dynamic
            this.currentTrainer = ''
            this.currentQuests = dynamic[0].quests
            this.currentQuest = ''
        }
        else {
        this.allDynamic = []
        this.currentTrainer = -1
        this.currentQuest = {
                id: -1,
                description: '',
                descriptionEn: '',
                dominantHand:{
                    description:'',
                    id: -1,
                    name: ''
                },
                name: '',
                nameEn: '',
                timeToComplete: ''
            }
        }
        this.currentData = []
    }

    @computed
    get getStatistic(){
        return {
            labels: this.labelsForChart,
            datasets: [
                {
                    label: 'Динамика выполнения упражнений',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgba(75,192,192,0.4)',
                    borderColor: 'rgba(75,192,192,1)',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: 'rgba(75,192,192,1)',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: this.dataForChart
                }
            ]
        }
    }

    @action
    setCurrentTrainer = async (id:number, patientId: number, token: string) => {
        this.currentTrainer = id
        this.allDynamic.forEach(elem => {
            if(elem.id === id) {
                this.currentQuests = elem.quests
                this.currentQuest = elem.quests[0]
            }
        })
        await this.changeData(patientId, token)
    }

    @action
    changeData = async (patientId: number, token: string, id: null | number = null) => {
        if(id){
            const response = await httpHelper(requests.getStatisticForPatient.url(patientId,id),
                requests.getStatisticForPatient.method, token)
            if(response.status == 'ok'){
                this.setDataDynamic(response.data)
            }
        }else if (this.currentQuest){
            const response = await httpHelper(requests.getStatisticForPatient.url(patientId, this.currentQuest.id),
                requests.getStatisticForPatient.method, token)
            if(response.status == 'ok'){
                this.setDataDynamic(response.data)
            }
        }
    }

    @action
    setCurrentQuest = async (id:number, patientId: number, token: string) => {
        this.currentQuests.forEach(elem => {
            if(elem.id === id)
                this.currentQuest = elem
        })
        await this.changeData(patientId, token)
    }

    @action
    setDataDynamic = (data:DynamicDataInterface[]) => this.currentData = data

    @computed
    get labelsForChart(){
        return this.currentData.map(elem => dateBeautifyFromUTC(elem.passingDate))
    }

    @computed
    get dataForChart(){
        return this.currentData.map(elem => dateBeautifyFromTimeString(elem.questTime))
    }
}
export default DynamicMob