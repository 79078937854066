import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface CustomSnackbarInterface {
    open: boolean,
    handleClose: Function,
    message: string,
    kind: "success" | "error" | "warning" | "info"
}

export default function CustomSnackbar({open, kind, handleClose, message}:CustomSnackbarInterface) {
    return (
            <Snackbar open={open} autoHideDuration={6000} onClose={() => handleClose()}>
                <Alert onClose={() => handleClose()} severity={kind}>
                    {message}
                </Alert>
            </Snackbar>
    );
}
