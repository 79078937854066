import {action, observable} from "mobx";
import {DoctorInterface, DoctorResponseInterface, DoctorTempResponseInterface} from "../interfaces/DoctorInterface";
import PatientMob from "./PatientMob";
import {ResponsePatientInterface} from "../interfaces/PatientInterface";

class DoctorMob {
    @observable doctor : DoctorInterface = {
        id: -1,
        token:'',
        name:'',
        isAuth: false
    }
    @observable.shallow patientList : PatientMob[] = []

    constructor(doctor: DoctorTempResponseInterface | null) {
        if(doctor){
            this.doctor = {
                id: doctor.id,
                token: '',
                name:doctor.name,
                isAuth: true
            }
        }else{
            this.doctor = {
                id: -1,
                token:'',
                name:'',
                isAuth: false
            }
            this.patientList = []
        }
    }

    @action
    addPatientToDoctor = (patient:ResponsePatientInterface) => {
        this.patientList.push(new PatientMob(patient))
    }

    @action
    cleanUpPatients = () => {this.patientList = []}

    @action
    removePatientFromDoctor = (patient: PatientMob) => {
        let index = this.patientList.findIndex(elem => elem === patient)
        if(index !== -1)
            this.patientList.splice(index, 1)
    }

    @action
    login = (doctor: DoctorResponseInterface, token:string, save:boolean) => {
        this.doctor = {
            id: doctor.id,
            token: token,
            name: `${doctor.surname} ${doctor.name} ${doctor.patronymic}`,
            isAuth: true
        }

        if (save)
            localStorage.setItem('user', token)
    }

    @action
    resetDoctor = () => {
        this.doctor = {
            id: -1,
            token:'',
            name:'',
            isAuth: false
        }
        this.patientList = []
        localStorage.clear()
    }

    @action
    getPatientById(patientId:number) {
        let patient = this.patientList.find(elem => elem.patient.id == patientId)
        return patient === undefined? new PatientMob(null) : patient
    }
}
export default DoctorMob