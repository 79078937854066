import React from "react";
import {List, ListItem, ListItemIcon, Paper, Typography} from "@material-ui/core";
import {usePatient} from "../../hooks/usePatient";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import {dateBeautifyFromServerToTrivial} from "../../helpers/beutifyDateHelpers";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WcIcon from '@material-ui/icons/Wc';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PanToolIcon from '@material-ui/icons/PanTool';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(2)
        },
    }),
);

export const PatientMainCard = () => {
    const classes = useStyles()
    const {patient, patientInfo, currentDomainHand} = usePatient()

    return(
        <Paper elevation={3} className={classes.paper}>
            <Typography variant={"h5"}>Информация о пациенте</Typography>
            <List>
                <ListItem>
                    <ListItemIcon>
                        <AccountCircleIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"ФИО"}
                        secondary={`${patient.name} ${patient.patronymic}`}
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <WcIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Пол"}
                        secondary={patientInfo.genderDto.name === "Male"?
                        "Мужской" : "Женский"}
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <CalendarTodayIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Дата рождения"}
                        secondary={dateBeautifyFromServerToTrivial(patientInfo.dateOfBirth)}
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <AssignmentIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Вес"}
                        secondary={`${patientInfo.weight} кг`}
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <AssignmentIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Рост"}
                        secondary={`${patientInfo.growth} см`}
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <PanToolIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Доминантная рука"}
                        secondary={currentDomainHand}
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <AccessibilityNewIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Поврежденнная сторона"}
                        secondary={patientInfo.damageLR === - 1? 'Левая': 'Правая'}
                    />
                </ListItem>
            </List>
        </Paper>
    )
}