import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useObserver} from "mobx-react-lite";
import {usePatientAdd} from "../../hooks/usePatientAdd";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

export default function BrokenSideSelect() {
    const classes = useStyles();
    const patient = usePatientAdd()
    const allHands = [
        {
            "id": -1,
            "name": "Левая",
            "description": "Left hand dominates"
        },
        {
            "id": 1,
            "name": "Правая",
            "description": "Right hand dominates"
        },
    ]

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        // @ts-ignore
        patient.setBrokenSide(+(event.target.value as string))
    };

    return useObserver(() => (
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Пораженная сторона</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={patient.patientToAdd.patientInfo.damageLR}
                onChange={handleChange}
            >
                {allHands.map((elem, index) =>(
                    <MenuItem key={index} value={elem.id}>{elem.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    ));
}
