import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Typography} from "@material-ui/core";
import {useObserver} from "mobx-react-lite";
import {useSession} from "../../hooks/useSession";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import {useHttp} from "../../hooks/useHttp";
import {ContentLoader} from "../ContentLoader";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        emptyText: {
            textAlign: 'center',
            color: theme.palette.text.disabled
        }
    }),
);


export default function QuestTable() {
    const classes = useStyles();
    const {loading} = useHttp()
    const session = useSession()

    return useObserver(() =>(
        <Paper className={classes.paper} elevation={3}>
            <Typography variant={"h5"}>Результат квестов</Typography>
            {loading? <ContentLoader message={"Загрузка текущих квестов..."}/>:
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Квест</TableCell>
                                <TableCell>Выполнение</TableCell>
                                <TableCell>Максимальное время выполнения</TableCell>
                                <TableCell>Время выполнения</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {session.questList.map((elem, index) => (
                                <TableRow key={index}>
                                    <TableCell>{elem.quest.name}</TableCell>
                                    <TableCell>{elem.isSuccessfullyFinished?
                                            <CheckCircleOutlineIcon color={"primary"}/> :
                                            <HighlightOffOutlinedIcon color={"secondary"}/>}</TableCell>
                                    <TableCell>{elem.quest.timeToComplete}</TableCell>
                                    <TableCell>{elem.questTime}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
            </TableContainer>}
            {session.questList.length === 0 &&
                <Typography
                    variant={"h6"}
                    className={classes.emptyText}
                >
                    В этой сессии не было выполнено ни одного квеста :(
                </Typography>}
        </Paper>
    ));
}
