import React, {SyntheticEvent, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {Copyright} from "../components/Copyright";
import {useDoctor} from "../hooks/useDoctor";
import { useHistory } from 'react-router-dom';
import {routes} from "../helpers/paths";
import {tempDoctor} from "../helpers/tempDoctorContant";
import {useHttp} from "../hooks/useHttp";
import {requests} from "../helpers/requests";
import {DoctorResponseInterface} from "../interfaces/DoctorInterface";
import CustomSnackbar from "../components/CustomSnackbar";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color:'#fff'
    },
}));

export default function Login() {
    const classes = useStyles();
    const doctor = useDoctor()
    const {clearError, request, loading, error} = useHttp()
    const [saveUser, setSaveUser] = useState<boolean>(false)
    const [authCredentials, setAuthCredentials] = useState<{email: string, password: string}>({email: '', password: ''})
    const history = useHistory()

    const handleChange = (event: SyntheticEvent) => {
        // @ts-ignore
        setAuthCredentials({...authCredentials, [event.currentTarget.name]: event.currentTarget.value})
    }

    const handleSubmit = async (event: React.FormEvent<EventTarget>) => {
        event.preventDefault()
        try {
            const data = await request(requests.login.url, requests.login.method,
                null, btoa(`${authCredentials.email}:${authCredentials.password}`))
            doctor.login(data as DoctorResponseInterface,
                btoa(`${authCredentials.email}:${authCredentials.password}`), saveUser)
            history.push(routes.toHome)
        }catch (e) {

        }
    }

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaveUser(event.currentTarget.checked)
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Войти
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleChange}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Пароль"
                        type="password"
                        id="password"
                        onChange={handleChange}
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={saveUser}
                                onChange={handleCheck}
                                color="primary"
                            />
                        }
                        label="Запомнить меня"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        className={classes.submit}
                    >
                        Войти
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
            <CustomSnackbar
                open={Boolean(error)}
                handleClose={clearError}
                message={"Неверный логин или пароль!"}
                kind={"error"}
            />
        </Container>
    );
}