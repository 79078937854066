export const routes = {
    toHome: '/home',
    toLogin: '/login',
    toRegistration: '/registration',
    toPatientPage: '/patient/:patientId',
    goToPatientPage: (patientId:number) => `/patient/${patientId}`,
    toSessionPage: '/session/:sessionId',
    goToSessionPage: (sessionId:number) => `/session/${sessionId}`,
    toUserDynamicPage: '/dynamic/:userId',
    toWaitingPage: '/waiting',
}