import {action, computed, observable} from "mobx";
import {ResponsePatientInterface} from "../interfaces/PatientInterface";

class PatientForCreate{
    @observable patientToAdd : ResponsePatientInterface = {} as ResponsePatientInterface

    constructor() {
        this.patientToAdd = {
            surname: '',
            email: '',
            patronymic:'',
            name: '',
            id: -1,
            patientInfo: {
                weight: -1,
                growth: -1,
                genderDto: {
                    id: -1,
                    name: ''
                },
                damageLR: '',
                dateOfBirth: '',
                dominantHandDto: {
                    description: '',
                    id: -1,
                    name: ''
                }
            }
        }
    }

    @computed
    get getUserForAddDoctor() {
        return {
            email: this.patientToAdd.email,
            name: this.patientToAdd.name,
            password: '12345',
            surname: this.patientToAdd.surname,
            patientInfo: {
                damageLR: this.patientToAdd.patientInfo.damageLR,
                dateOfBirth: this.patientToAdd.patientInfo.dateOfBirth,
                dominantHandDto: {
                    id: this.patientToAdd.patientInfo.dominantHandDto.id
                },
                genderDto: {
                    id: this.patientToAdd.patientInfo.genderDto.id
                },
                growth: this.patientToAdd.patientInfo.growth,
                weight: this.patientToAdd.patientInfo.weight
            },
            patronymic: this.patientToAdd.patronymic,
        }
    }

    @action
    setGenderId = (genderId:number) => this.patientToAdd.patientInfo.genderDto.id = genderId

    @action
    setSurname = (surname:string) => this.patientToAdd.surname = surname

    @action
    setName = (name:string) => this.patientToAdd.name = name

    @action
    setPatromic = (patromic:string) => this.patientToAdd.patronymic = patromic

    @action
    setEmail = (email:string) => this.patientToAdd.email = email

    @action
    setBrokenSide = (id:-1 | 1) => this.patientToAdd.patientInfo.damageLR = id

    @action
    setDomainHandId = (id:number) => this.patientToAdd.patientInfo.dominantHandDto.id = id

    @action
    setGrowth = (growth:number) => this.patientToAdd.patientInfo.growth = growth

    @action
    setWeight = (weight:number) => this.patientToAdd.patientInfo.weight = weight

    @action
    setBirthday = (birthday:string) => this.patientToAdd.patientInfo.dateOfBirth = birthday
}
export default PatientForCreate