import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Grid, IconButton, Typography} from "@material-ui/core";
import AddSession from "../../components/PatientPage/CreateSessionForPatient";
import {usePatient} from "../../hooks/usePatient";
import {useObserver} from "mobx-react-lite";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';
import {routes} from "../../helpers/paths";
import {useHttp} from "../../hooks/useHttp";
import {requests} from "../../helpers/requests";
import {useDoctor} from "../../hooks/useDoctor";
import { SessionResponseInterface } from '../../interfaces/SessionInterface';
import {ContentLoader} from "../../components/ContentLoader";
import {format} from "date-fns";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        emptyText: {
            textAlign: 'center',
            color: theme.palette.text.disabled,
        }
    }),
);


export default function SessionList() {
    const classes = useStyles();
    const patient = usePatient()
    const history = useHistory()
    const {loading, request} = useHttp()
    const {token} = useDoctor().doctor
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        request(requests.getSessionsByPatientId.url(patient.patient.id), requests.getSessionsByPatientId.method,
            null, token)
            .then(data => {
                data.map((elem: SessionResponseInterface) => patient.addSession(elem))
                setUpdate(false)
            })
            .catch(() => {
                setUpdate(false)
            })
        return () => {
            patient.clearSessions()
        }
    },[patient.patient.id, update])

    return useObserver(() =>(
        <Paper className={classes.paper} elevation={3}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <Typography variant={"h5"}>Сессии</Typography>
                </Grid>
                <Grid item>
                    <AddSession update={() => setUpdate(true)}/>
                </Grid>
            </Grid>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID сессии</TableCell>
                            <TableCell>Дата</TableCell>
                            <TableCell>Время</TableCell>
                            <TableCell>Описание</TableCell>
                            <TableCell colSpan={1}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading? <ContentLoader message={"Загрузка сессий..."}/>:
                        <>
                            {patient.sessions.map((elem, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {elem.session.id}
                                    </TableCell>
                                    <TableCell>
                                        {format(new Date(elem.session.creationDateTime), 'dd.MM.yyyy')}
                                    </TableCell>
                                    <TableCell>
                                        {format(new Date(elem.session.creationDateTime), 'HH:mm')}
                                    </TableCell>
                                    <TableCell>
                                        {elem.session.description? elem.session.description: 'Описание не найдено'}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => history.push(routes.goToSessionPage(+elem.session.id))}>
                                            <VisibilityIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </>}
                    </TableBody>
                </Table>
            </TableContainer>
            {patient.sessions.length === 0 &&
            <Typography
                variant={"h6"}
                className={classes.emptyText}
            >
                У Вас не привязаны сессии. Привяжите их, чтобы увидеть!
            </Typography>}
        </Paper>
    ));
}
