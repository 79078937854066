import {action, computed, observable} from "mobx";
import {PatientInfoInterface, PatientInterface, ResponsePatientInterface} from "../interfaces/PatientInterface";
import { SessionResponseInterface} from "../interfaces/SessionInterface";
import SessionMob from "./SessionMob";


class PatientMob {
    @observable patient : PatientInterface = {} as PatientInterface
    @observable patientInfo : PatientInfoInterface = {} as PatientInfoInterface
    @observable.shallow sessions : SessionMob[] = []

    constructor(patient : ResponsePatientInterface | null) {
        if(patient){
            this.patient = {
                id: patient.id,
                name: `${patient.surname} ${patient.name}`,
                patronymic: patient.patronymic,
                email: patient.email
            }
            this.patientInfo = patient.patientInfo as PatientInfoInterface
        }
        else{
            this.patient = {
                id: -1,
                name: '',
                email: '',
                patronymic: ''
            }
            this.patientInfo = {
                weight: -1,
                growth: -1,
                genderDto: {
                    id: -1,
                    name: ''
                },
                damageLR: -1,
                dateOfBirth: '',
                dominantHandDto: {
                    description: '',
                    id: -1,
                    name: ''
                }
            }

        }
        this.sessions = []
    }

    @action
    addSession = (session: SessionResponseInterface) => {
        this.sessions.push(new SessionMob(session))
    }

    @action
    clearSessions = () => {
        this.sessions = []
    }

    @computed
    get currentDomainHand(){
        let str = ''
        switch (this.patientInfo.dominantHandDto.name) {
            case "Left":
                str = 'Левая рука'
                break
            case "Right":
                str = "Правая рука"
                break
            case "Both hands":
                str = "Не имеет"
                break
        }
        return str
    }

}
export default PatientMob