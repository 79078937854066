import {action, observable} from "mobx";
import {SessionInterface, SessionResponseInterface} from "../interfaces/SessionInterface";
import {QuestInterface} from "../interfaces/QuestInterface";

class SessionMob {
    @observable session: SessionInterface = {
        id: -1,
        helmetIdentifier: '',
        creationDateTime: '',
        finishDateTime: '',
        isFinished: false,
        description: ''
    }
    @observable.shallow questList: QuestInterface[] = []

    constructor(session: SessionResponseInterface | null) {
        if(session){
            this.session = {
                id: session.id,
                helmetIdentifier: session.helmetIdentifier,
                creationDateTime: session.creationDateTime,
                finishDateTime: session.finishDateTime,
                isFinished: session.isFinished,
                description: session.description
            }
        }
        else{
            this.session = {
                id: -1,
                helmetIdentifier: '',
                creationDateTime: '',
                finishDateTime: '',
                isFinished: false,
                description: ''
            }
        }

        this.questList = []
    }
    @action
    addQuest = (quest:QuestInterface) => {
        this.questList.push(quest)
    }

    @action
    editDescription = (description:string) => this.session.description = description

    @action
    shutDownSession = () => this.session.isFinished = true
}

export default SessionMob