import {Grid, Paper, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import TrainerSelect from "../../components/PatientPage/TrainerSelect";
import QuestSelect from "../../components/PatientPage/QuestSelect";
import {ChartDynamic} from "../../components/PatientPage/ChartDynamic";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useHttp} from "../../hooks/useHttp";
import {useDoctor} from "../../hooks/useDoctor";
import DynamicMob from "../../store/DynamicMob";
import {requests} from "../../helpers/requests";
import {ContentLoader} from "../../components/ContentLoader";
import {DynamicProvider} from "../../context/DynamicProvider";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainPaper: {
            marginTop: theme.spacing(3),
            padding: theme.spacing(2)
        }
    }),
);

export const DynamicCard = () => {
    const classes = useStyles()
    const {token} = useDoctor().doctor
    const {request} = useHttp()
    const [dynamic, setDynamic] = useState<DynamicMob>(new DynamicMob(null))
    const {patientId} = useParams()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        request(requests.getAllQuestsId.url, requests.getAllQuestsId.method, null, token)
            .then( data => {
                setDynamic(new DynamicMob(data))
                dynamic.changeData(+patientId, token, data[0].quests[0].id)
                    .then(() => setLoading(false))
                    .catch(() => setLoading(false))
            })
            .catch(() => setDynamic(new DynamicMob(null)))
    },[patientId])

    return (
        <Paper elevation={3} className={classes.mainPaper}>
            <Typography variant={"h5"}>
                Динамика выполнения упражнений
            </Typography>
            {loading? <ContentLoader message={"Загрузка данных..."}/>:
            <DynamicProvider value={dynamic}>
                <Grid container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                >
                        <TrainerSelect/>
                        <QuestSelect/>
                </Grid>
                <ChartDynamic/>
            </DynamicProvider>}
        </Paper>
    )
}