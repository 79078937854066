import React, {SyntheticEvent, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Fab, TextField} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {usePatient} from "../../hooks/usePatient";
import {useDoctor} from "../../hooks/useDoctor";
import {useHttp} from "../../hooks/useHttp";
import {requests} from "../../helpers/requests";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            color: '#fff'
        },
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
    })
);


export default function AddSession({update}:{update:Function}) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles()
    const patient = usePatient()
    const {token} = useDoctor().doctor
    const {loading, request} = useHttp()
    // @ts-ignore
    const [session, setSession] = useState<{ id: string}>({id: -1})

    const handleChange = (event: SyntheticEvent) => {
        // @ts-ignore
        setSession({...session, [event.currentTarget.name]: event.currentTarget.value})
    }

    const handleAdd = () => {
        request(requests.linkSessionToPatient.url(session.id, patient.patient.id),
            requests.linkSessionToPatient.method, null, token)
            .then( () => {
                handleClose()
                update()
            })
            .catch(() => handleClose())
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setOpen(true)}
                size={"small"}
                className={classes.fab}
            >
                <AddIcon />
            </Fab>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Добавление новой сессии"}</DialogTitle>
                <DialogContent>
                    <form className={classes.root}>
                        <TextField
                            id="standard-basic"
                            label="Идентефикатор сессии"
                            name={"id"}
                            onChange={handleChange}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button
                        onClick={handleAdd}
                        color="primary"
                        autoFocus
                        disabled={loading}
                    >
                        Добавить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
