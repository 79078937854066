import React, {useEffect} from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import {Grid, Paper, Typography} from "@material-ui/core";
import {useDoctor} from "../../hooks/useDoctor";
import {PatientListItem} from "../../components/PatientListPage/PatientListItem";
import AddPatient from "./AddPatient";
import {useObserver} from "mobx-react-lite";
import {useHttp} from "../../hooks/useHttp";
import {requests} from "../../helpers/requests";
import { ResponsePatientInterface } from '../../interfaces/PatientInterface';
import {ContentLoader} from "../../components/ContentLoader";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        paper: {
            margin: theme.spacing(5, 0),
            minHeight: 300,
            padding: theme.spacing(2)
        },
        grid: {
            marginBottom: theme.spacing(2)
        },
        emptyText: {
            textAlign: 'center',
            color: theme.palette.text.disabled
        }
    }),
);

export default function ListOfPatients() {
    const classes = useStyles();
    const doctor = useDoctor()
    const {loading, request} = useHttp()

    useEffect(() => {
        request(requests.getMyPatients.url, requests.getMyPatients.method, null, doctor.doctor.token)
            .then(data => {
                data.map((elem: ResponsePatientInterface) => doctor.addPatientToDoctor(elem))
            })
        return () => {
            doctor.cleanUpPatients()
        }
    }, [])

    return useObserver(() => (
        <>
            {loading? <ContentLoader message={"Загрузка пациентов..."}/>:
            <>
                <Paper className={classes.paper} elevation={5}>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}
                        className={classes.grid}
                    >
                        <Grid item>
                            <Typography
                                variant={"h5"}
                                component={"h2"}
                            >
                                Список пациентов
                            </Typography>
                        </Grid>
                        <Grid item>
                            <AddPatient/>
                        </Grid>
                    </Grid>
                    <List className={classes.root}>
                        {doctor.patientList.map((elem, index) => (
                            <PatientListItem
                                key={index}
                                patient={elem}
                                removePatient={() => doctor.removePatientFromDoctor(elem)}
                            />
                        ))}
                        {doctor.patientList.length === 0 &&
                        <Typography
                            variant={"h4"}
                            className={classes.emptyText}
                        >
                            У вас еще нет пациентов. Добавьте их, чтобы видеть их сессии
                        </Typography>}
                    </List>
                </Paper>
            </>}
        </>
    ));
}