import React, {useEffect, useState} from "react";
import Header from "../components/Header/Header";
import {Container} from "@material-ui/core";
import {useObserver} from "mobx-react-lite";
import {PatientProvider} from "../context/PatientProvider";
import { useParams } from "react-router-dom";
import PatientMob from "../store/PatientMob";
import {useDoctor} from "../hooks/useDoctor";
import {PatientMainCard} from "../components/PatientPage/PatientMainCard";
import SessionList from "../containers/PatientPage/SessionList";
import {DynamicCard} from "../containers/PatientPage/DynamicCard";
import {useHttp} from "../hooks/useHttp";
import {requests} from "../helpers/requests";
import {ContentLoader} from "../components/ContentLoader";

export const PatientPage = () => {
    const {patientId} = useParams()
    const doctor = useDoctor()
    const {loading, request} = useHttp()
    const [patient, setPatient] = useState<PatientMob>(new PatientMob(null))

    useEffect(() => {
       request(requests.getPatient.url(patientId), requests.getPatient.method, null, doctor.doctor.token)
           .then( data => setPatient(new PatientMob(data)))
           .catch(() => setPatient(new PatientMob(null)))
    },[])

    return useObserver(() => (
        <PatientProvider value={patient}>
            <Header/>
            <Container>
                {loading? <ContentLoader message={"Загрузка данных пациента..."}/> :
                <>
                    <PatientMainCard/>
                    <DynamicCard/>
                    <SessionList/>
                </>}
            </Container>
        </PatientProvider>
    ))
}