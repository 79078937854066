import React from 'react';
import {Line} from 'react-chartjs-2';
import {Paper, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useDynamics} from "../../hooks/useDynamics";
import {useObserver} from "mobx-react-lite";



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainPaper: {
            marginTop: theme.spacing(3)
        },
        chartPaper:{
            maxHeight: 550,
            minHeight: 120
        },
        emptyText: {
            textAlign: 'center',
            marginTop: theme.spacing(3),
            color: theme.palette.text.disabled
        },
    }),
);

export const ChartDynamic = () => {
    const classes = useStyles()
    const dynamic = useDynamics()
    const options = {
        scales: {
            yAxes: [{
                ticks: {
                    suggestedMin: 0
                }
            }]
        }
    }

    return useObserver(() => (
        <Paper className={classes.chartPaper}>
            {dynamic.dataForChart.length === 0?
                <Typography variant={"h5"} className={classes.emptyText}>
                    Выберите данные для отображения
                </Typography>:
                <Line data={dynamic.getStatistic} options={options} height={120}/>}
        </Paper>
    ))
}