
export const dateBeautifyFromUTC = (date:string) => {
    let nDate = new Date(date)
    let month = '' + (nDate.getMonth() + 1),
        day = '' + nDate.getDate(),
        year = nDate.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('.');
}

export const dateBeautifyFromTimeString = (timeString: string) => {
    let temp = timeString.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    return (+temp[0]) * 60 * 60 + (+temp[1]) * 60 + (+temp[2]);
}

export const timeBeautifyFromUTCtoTimeString = (utcTime:string) => {
    let temp = new Date(utcTime)
    return temp.toTimeString().split(' ')[0].slice(0,5)
}

export const dateBeautifyFromDateToServerString = (date: Date) => {
      let  month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const dateBeautifyFromServerToTrivial = (oldDate: string) => {
    let temp = oldDate.split('-')
    return `${temp[2]}.${temp[1]}.${temp[0]}`
}