import React, {useEffect, useState} from "react"
import Header from "../components/Header/Header";
import {Container} from "@material-ui/core";
import SessionMob from "../store/SessionMob";
import {SessionProvider} from "../context/SessionProvider";
import QuestTable from "../components/SessionPage/QuestTable";
import {useDoctor} from "../hooks/useDoctor";
import {useHttp} from "../hooks/useHttp";
import {requests} from "../helpers/requests";
import { useParams } from "react-router-dom";
import {ContentLoader} from "../components/ContentLoader";
import {QuestInterface} from "../interfaces/QuestInterface";
import {SessionMainCard} from "../containers/SessionPage/SessionMainCard";


export const SessionPage = () => {
    const [session, setSession] = useState<SessionMob>(new SessionMob(null))
    const {token} = useDoctor().doctor
    const { loading, request } = useHttp()
    const {sessionId} = useParams()

    useEffect(() => {
        let tempSession = new SessionMob(null)
        request(requests.fullSessionGet.url(+sessionId), requests.fullSessionGet.method, null, token)
            .then(data =>{
                tempSession = new SessionMob(data.session)
                data.sessionResults.map((elem: QuestInterface) => tempSession.addQuest(elem))
                setSession(tempSession)
            })
            .catch(() => setSession(tempSession))
    },[sessionId])

    return(
        <SessionProvider value={session}>
            <Header/>
            <Container>
                {loading? <ContentLoader message={"Загрузка сессии..."}/>:
                    <>
                        <SessionMainCard/>
                        <QuestTable/>
                    </>}
            </Container>
        </SessionProvider>
    )
}
