import React, {useState} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {InputAdornment} from "@material-ui/core";
import GenderSelect from "../../components/PatientListPage/GenderSelect";
import DomainHandSelect from "../../components/PatientListPage/DomainHandSelect";
import BrokenSideSelect from "../../components/PatientListPage/BrokenSideSelect";
import BirthdayPicker from "../../components/PatientListPage/BirthdayPicker";
import {usePatientAdd} from "../../hooks/usePatientAdd";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
    }),
);


export default function PatientAddForm() {
    const classes = useStyles();
    const patient = usePatientAdd()
    const [error, setError] = useState<boolean>(false)

    const validateEmail = (email:string) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <TextField
                id="standard-basic"
                name={"name"}
                label="Фамилия"
                onChange={event => patient.setSurname(event.target.value as string)}
            />
            <TextField
                id="standard-basic"
                name={"name"}
                label="Имя"
                onChange={event => patient.setName(event.target.value as string)}
            />
            <TextField
                id="standard-basic"
                name={"name"}
                label="Отчество"
                onChange={event => patient.setPatromic(event.target.value as string)}
            />
            <TextField
                id="standard-basic"
                name={"Email"}
                label="Email"
                error={error}
                helperText={error && 'Неверный формат email'}
                onChange={event => {
                    patient.setEmail(event.target.value as string)
                    setError(!validateEmail(event.target.value as string))
                }}
            />
            <TextField
                id="standard-basic"
                label="Рост"
                name={"growth"}
                InputProps={{
                    endAdornment: <InputAdornment position="end">См</InputAdornment>,
                }}
                type="number"
                onChange={event => patient.setGrowth(+(event.target.value as string))}
            />
            <TextField
                id="standard-basic"
                label="Вес"
                name={"weight"}
                InputProps={{
                    endAdornment: <InputAdornment position="end">Кг</InputAdornment>,
                }}
                type="number"
                onChange={event => patient.setWeight(+(event.target.value as string))}
            />
            <GenderSelect/>
            <DomainHandSelect/>
            <BrokenSideSelect/>
            <BirthdayPicker/>
        </form>
    );
}
