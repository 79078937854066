import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useDynamics} from "../../hooks/useDynamics";
import {useObserver} from "mobx-react-lite";
import {usePatient} from "../../hooks/usePatient";
import {useDoctor} from "../../hooks/useDoctor";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            width: '50%'
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

export default function QuestSelect() {
    const classes = useStyles();
    const dynamic = useDynamics()
    const {id} = usePatient().patient
    const {token} = useDoctor().doctor

    const handleChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
        await dynamic.setCurrentQuest(+(event.target.value as string), id, token)
    };

    return useObserver(() => (
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Квест</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                disabled={dynamic.currentTrainer === ''}
                value={dynamic.currentQuest? dynamic.currentQuest.id:''}
                onChange={handleChange}
            >
                {dynamic.currentQuests.map((elem, index) => (
                    <MenuItem key={index} value={elem.id}>{elem.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    ));
}
