import React from "react";
import Header from "../components/Header/Header";
import ListOfPatients from "../containers/PatientLIstPage/ListOfPatients";
import {Container} from "@material-ui/core";
import {useObserver} from "mobx-react-lite";

export const HomePage = ()=> {

    return useObserver(() => (
        <>
            <Header/>
            <Container>
                <ListOfPatients/>
            </Container>
        </>
    ))
}