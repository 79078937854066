import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Fab} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import {createStyles, makeStyles} from "@material-ui/core/styles";
import PatientAddForm from "./PatientAddForm";
import {useDoctor} from "../../hooks/useDoctor";
import {useHttp} from "../../hooks/useHttp";
import {requests} from "../../helpers/requests";
import CustomSnackbar from "../../components/CustomSnackbar";
import {PatientToAddProvider} from "../../context/PatientAddProvider";
import PatientForCreate from "../../store/PatientForCreate";

const useStyles = makeStyles(() =>
    createStyles({
        fab: {
            color: '#fff'
        },
    })
);

export default function AddPatient() {
    const [open, setOpen] = React.useState(false);
    const doctor = useDoctor()
    const [patient, setPatient] = useState<PatientForCreate>(new PatientForCreate())
    const classes = useStyles()
    const {request, loading, error, clearError} = useHttp()

    const handleClose = () => {
        setOpen(false);
    };

    const handleAdd = () => {
        request(requests.autoLinkDoctor.url, requests.autoLinkDoctor.method,
            patient.getUserForAddDoctor, doctor.doctor.token)
            .then(data => {
                doctor.addPatientToDoctor(data)
                handleClose()
            })
            .catch(() => {})
    }


    return (
        <div>
            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setOpen(true)}
                size={"small"}
                className={classes.fab}
            >
                <AddIcon />
            </Fab>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Добавление нового пациента"}</DialogTitle>
                <DialogContent>
                    <PatientToAddProvider value={patient}>
                        <PatientAddForm/>
                    </PatientToAddProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button disabled={loading} onClick={() => handleAdd()} color="primary" autoFocus>
                        Добавить
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomSnackbar
                open={!!error}
                handleClose={() => clearError()}
                message={"Что-то пошло не так при создании. Проверьте правильность полей!"}
                kind={"error"}
            />
        </div>
    );
}
