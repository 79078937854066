import {useDoctor} from "./useDoctor";
import {useEffect} from "react";
import {useObserver} from "mobx-react-lite";
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {routes} from "../helpers/paths";
import {HomePage} from "../pages/HomePage";
import {PatientPage} from "../pages/PatientPage";
import Login from "../pages/Login";
import Registration from "../pages/Registration";
import {UserDynamicPage} from "../pages/UserDynamicPage";
import {SessionPage} from "../pages/SessionPage";
import {requests} from "../helpers/requests";
import {DoctorResponseInterface} from "../interfaces/DoctorInterface";
import {useHttp} from "./useHttp";
import {WaitingPage} from "../pages/WaitingPage";

export const useRoutes = () => {
    const doctor = useDoctor()
    const {request, loading, clearError} = useHttp()

    useEffect(() => {
        const token = localStorage.getItem('user')
        request(requests.login.url, requests.login.method, null, token)
            .then(data => {
                doctor.login(data as DoctorResponseInterface, token? token : '', true)
            })
            .catch( () => {
                doctor.resetDoctor()
                clearError()
            })
    },[])

    return useObserver(() => (
        <Switch>
            {loading?
                    <>
                        <Route exact path={routes.toWaitingPage}>
                            <WaitingPage/>
                        </Route>
                        <Redirect to={routes.toWaitingPage}/>
                    </>:
            <>
                {doctor.doctor.isAuth ? <>
                    <Route exact path={routes.toHome}>
                        <HomePage/>
                    </Route>
                    <Route path={routes.toPatientPage}>
                        <PatientPage/>
                    </Route>
                    <Route path={routes.toUserDynamicPage}>
                        <UserDynamicPage/>
                    </Route>
                    <Route path={routes.toSessionPage}>
                        <SessionPage/>
                    </Route>
                    <Redirect to={routes.toHome}/>
                </>:<>
                    <Route exact path={routes.toLogin}>
                        <Login/>
                    </Route>
                    <Route path={routes.toRegistration}>
                        <Registration/>
                    </Route>
                    <Redirect to={routes.toLogin}/>
                </>}
            </>}
        </Switch>
    ))
}